import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'

/**
 * @typedef {ThemeService}
 * @alias this.$themeService
 */
export class ThemeService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
  }

  init() {
    this.$t = this.nuxtApp.$i18n.t
    this.$eventService = this.nuxtApp.$eventService

    // Computed ref
    this.locale = this.nuxtApp.$i18n.locale
  }

  get currentLocale() {
    return this.locale.value
  }

  get weddingTheme() {
    const buttonLeftPositions = {
      nl: '26.5%',
      en: '25.7%',
      de: '20.2%',
      es: '25.4%',
      fr: '21.7%',
    }
    const buttonTopPositions = {
      nl: '45.5%',
      en: '45.5%',
      de: '45.5%',
      es: '45.5%',
      fr: '45.5%',
    }
    const mobileButtonLeftPositions = {
      nl: '20%',
      en: '19.8%',
      de: '9.5%',
      es: '19.3%',
      fr: '14.5%',
    }
    const mobileButtonTopPositions = {
      nl: '47.5%',
      en: '47.5%',
      de: '47.5%',
      es: '47.5%',
      fr: '47.5%',
    }

    return {
      name: this.$t('theme_translations.wedding.theme_name'),
      image: '/themes/wedding/card-image.jpg',
      properties: {
        [EVENT_PROPERTY_KEY.FONT]: 'Dancing Script',
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.wedding.image_upload_text'),
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
        [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#4D1C00B2',
        [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#4d1c00',
        [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/wedding/background.jpg',
        [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#4d1c00',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
        [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: buttonLeftPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: buttonTopPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

        // Mobile
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '4',
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: mobileButtonLeftPositions[this.currentLocale],
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: mobileButtonTopPositions[this.currentLocale],
      },
    }
  }

  get partyTheme() {
    const buttonLeftPositions = {
      nl: '20.5%',
      en: '18.5%',
      de: '10.5%',
      es: '18.9%',
      fr: '12.4%',
    }
    const buttonTopPositions = {
      nl: '45.5%',
      en: '45.5%',
      de: '45.5%',
      es: '45.5%',
      fr: '45.5%',
    }
    const mobileButtonLeftPositions = {
      nl: '20%',
      en: '19.7%',
      de: '10.5%',
      es: '20.1%',
      fr: '13.3%',
    }
    const mobileButtonTopPositions = {
      nl: '48%',
      en: '48%',
      de: '48%',
      es: '48%',
      fr: '48%',
    }

    return {
      name: this.$t('theme_translations.party.theme_name'),
      image: '/themes/party/card-image.jpg',
      properties: {
        [EVENT_PROPERTY_KEY.FONT]: 'Merriweather Sans',
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.party.image_upload_text'),
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
        [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#1511CFa1',
        [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#000000',
        [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/party/background.jpg',
        [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#1511CF',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
        [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: buttonLeftPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: buttonTopPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '2',

        // Mobile
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: mobileButtonLeftPositions[this.currentLocale],
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: mobileButtonTopPositions[this.currentLocale],
      },
    }
  }

  get sportTheme() {
    const buttonLeftPositions = {
      nl: '26.5%',
      en: '26.4%',
      de: '20.7%',
      es: '26.7%',
      fr: '22%',
    }
    const buttonTopPositions = {
      nl: '46%',
      en: '46%',
      de: '46%',
      es: '46%',
      fr: '46%',
    }
    const mobileButtonLeftPositions = {
      nl: '20%',
      en: '20.8%',
      de: '13%',
      es: '18.5%',
      fr: '14.7%',
    }
    const mobileButtonTopPositions = {
      nl: '48%',
      en: '48%',
      de: '48%',
      es: '48%',
      fr: '48%',
    }

    return {
      name: this.$t('theme_translations.sport.theme_name'),
      image: '/themes/sport/card-image.jpg',
      properties: {
        [EVENT_PROPERTY_KEY.FONT]: 'Bebas Neue',
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.sport.image_upload_text'),
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
        [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#000000B2',
        [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#000000',
        [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/sport/background.jpg',
        [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#000000',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
        [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: buttonLeftPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: buttonTopPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

        // Mobile
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '4',
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: mobileButtonLeftPositions[this.currentLocale],
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: mobileButtonTopPositions[this.currentLocale],
      },
    }
  }

  get companyTheme() {
    const buttonLeftPositions = {
      nl: '20.5%',
      en: '20.1%',
      de: '12.7%',
      es: '20.2%',
      fr: '14.4%',
    }
    const buttonTopPositions = {
      nl: '45.5%',
      en: '45.5%',
      de: '45.5%',
      es: '45.5%',
      fr: '45.5%',
    }
    const mobileButtonLeftPositions = {
      nl: '20%',
      en: '21.2%',
      de: '12.5%',
      es: '18.6%',
      fr: '15.3%',
    }
    const mobileButtonTopPositions = {
      nl: '48%',
      en: '48%',
      de: '48%',
      es: '48%',
      fr: '48%',
    }

    return {
      name: this.$t('theme_translations.company.theme_name'),
      image: '/themes/company/card-image.jpg',
      properties: {
        [EVENT_PROPERTY_KEY.FONT]: 'Bree Serif',
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.company.image_upload_text'),
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
        [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#783200B2',
        [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#783200',
        [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/company/background.jpg',
        [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#783200',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
        [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: buttonLeftPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: buttonTopPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

        // Mobile
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: mobileButtonLeftPositions[this.currentLocale],
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: mobileButtonTopPositions[this.currentLocale],
      },
    }
  }

  get festivalTheme() {
    const buttonLeftPositions = {
      nl: '20.5%',
      en: '18.6%',
      de: '11%',
      es: '18.9%',
      fr: '12.6%',
    }
    const buttonTopPositions = {
      nl: '45.5%',
      en: '45.5%',
      de: '45.5%',
      es: '45.5%',
      fr: '45.5%',
    }
    const mobileButtonLeftPositions = {
      nl: '20%',
      en: '19.6%',
      de: '11%',
      es: '20.1%',
      fr: '13.7%',
    }
    const mobileButtonTopPositions = {
      nl: '48.2%',
      en: '48.2%',
      de: '48.2%',
      es: '48.2%',
      fr: '48.2%',
    }

    return {
      name: this.$t('theme_translations.festival.theme_name'),
      image: '/themes/festival/card-image.jpg',
      properties: {
        [EVENT_PROPERTY_KEY.FONT]: 'Open Sans',
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.festival.image_upload_text'),
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
        [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#ffffff',
        [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#ffffff',
        [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/festival/background.jpg',
        [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#101010',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#101010',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
        [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: buttonLeftPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: buttonTopPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

        // Mobile
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: mobileButtonLeftPositions[this.currentLocale],
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: mobileButtonTopPositions[this.currentLocale],
      },
    }
  }

  get birthdayTheme() {
    const buttonLeftPositions = {
      nl: '20.5%',
      en: '16.4%',
      de: '13%',
      es: '20.1%',
      fr: '14.7%',
    }
    const buttonTopPositions = {
      nl: '45.5%',
      en: '45.5%',
      de: '45.5%',
      es: '45.5%',
      fr: '45.5%',
    }
    const mobileButtonLeftPositions = {
      nl: '20%',
      en: '17.5%',
      de: '13%',
      es: '21.1%',
      fr: '16.1%',
    }
    const mobileButtonTopPositions = {
      nl: '48.3%',
      en: '48.3%',
      de: '48.3%',
      es: '48.3%',
      fr: '48.3%',
    }

    return {
      name: this.$t('theme_translations.birthday.theme_name'),
      image: '/themes/birthday/card-image.jpg',
      properties: {
        [EVENT_PROPERTY_KEY.FONT]: 'Roboto',
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.birthday.image_upload_text'),
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
        [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#BD00FFB2',
        [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#BD00FF',
        [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/birthday/background.jpg',
        [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#BD00FF',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
        [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: buttonLeftPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: buttonTopPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

        // Mobile
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: mobileButtonLeftPositions[this.currentLocale],
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: mobileButtonTopPositions[this.currentLocale],
      },
    }
  }

  get tripTheme() {
    const buttonLeftPositions = {
      nl: '20.5%',
      en: '16.5%',
      de: '9%',
      es: '16.7%',
      fr: '10.2%',
    }
    const buttonTopPositions = {
      nl: '45.5%',
      en: '45.5%',
      de: '45.5%',
      es: '45.5%',
      fr: '45.5%',
    }
    const mobileButtonLeftPositions = {
      nl: '20%',
      en: '17.3%',
      de: '8.7%',
      es: '17.8%',
      fr: '11.2%',
    }
    const mobileButtonTopPositions = {
      nl: '48.3%',
      en: '48.3%',
      de: '48.3%',
      es: '48.3%',
      fr: '48.3%',
    }

    return {
      name: this.$t('theme_translations.trip.theme_name'),
      image: '/themes/trip/card-image.jpg',
      properties: {
        [EVENT_PROPERTY_KEY.FONT]: 'Prompt',
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.trip.image_upload_text'),
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
        [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#FFFFFFB2',
        [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#ffffff',
        [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/trip/background.jpg',
        [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#000000',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#424242',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
        [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: buttonLeftPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: buttonTopPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

        // Mobile
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: mobileButtonLeftPositions[this.currentLocale],
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: mobileButtonTopPositions[this.currentLocale],
      },
    }
  }

  /**
   * @return {Array}
   */
  getThemes() {
    return [
      this.weddingTheme,
      this.partyTheme,
      this.sportTheme,
      this.companyTheme,
      this.festivalTheme,
      this.birthdayTheme,
      this.tripTheme,
    ]
  }

  get initialCustomTheme() {
    const buttonLeftPositions = {
      nl: '20.5%',
      en: '16.4%',
      de: '8.9%',
      es: '16.7%',
      fr: '11.2%',
    }
    const buttonTopPositions = {
      nl: '45.9%',
      en: '45.9%',
      de: '45.9%',
      es: '45.9%',
      fr: '45.9%',
    }
    const mobileButtonLeftPositions = {
      nl: '20%',
      en: '17.5%',
      de: '9%',
      es: '7.6%',
      fr: '11.2%',
    }
    const mobileButtonTopPositions = {
      nl: '47.8%',
      en: '47.8%',
      de: '47.8%',
      es: '47.8%',
      fr: '47.8%',
    }

    return {
      name: this.$t('theme_translations.custom.theme_name'),
      image: '/themes/custom/card-image.jpg',
      properties: {
        [EVENT_PROPERTY_KEY.FONT]: 'Prompt',
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.custom.image_upload_text'),
        [EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT]: 'center',
        [EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR]: '#FFFFFFB2',
        [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#ffffff',
        [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/custom/background.jpg',
        [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#000000',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR]: '#424242',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED]: true,
        [EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: buttonLeftPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: buttonTopPositions[this.currentLocale],
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        [EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH]: '1',

        // Mobile
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE]: '3',
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION]: mobileButtonLeftPositions[this.currentLocale],
        ['mobile_' + EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION]: mobileButtonTopPositions[this.currentLocale],
      },
    }
  }

  /**
   * @param {momentshare.models.event.Event} event
   * @param {Object} theme
   */
  getThemeIsActive(theme, event) {
    return event[EVENT_PROPERTY_KEY.BACKGROUND_IMAGE] === theme.properties[EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]
  }

  /**
   * @param {momentshare.models.event.Event} event
   * @return Object|null
   */
  getCurrentThemeByEvent(event) {
    return this.getThemes().find((theme) => this.getThemeIsActive(theme, event)) ?? null
  }

  resetEventToTheme(eventId, theme) {
    const formData = new FormData()

    // Reset original image paths
    formData.set(this.getMappedEventPropertyKey(EVENT_PROPERTY_KEY.ORIGINAL_BACKGROUND_IMAGE), null)
    formData.set(this.getMappedEventPropertyKey(EVENT_PROPERTY_KEY.ORIGINAL_BACKGROUND_IMAGE, true), null)

    // Reset image states
    formData.set(this.getMappedEventPropertyKey('backgroundImageState'), null)
    formData.set(this.getMappedEventPropertyKey('backgroundImageState', true), null)

    // Remove deprecated properties
    formData.set(this.getMappedEventPropertyKey(EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD), null)
    formData.set(this.getMappedEventPropertyKey(EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD, true), null)

    // Set all theme properties
    Object.keys(theme.properties).forEach((key) => {
      // Set base property
      const value = theme.properties[key] ?? null

      formData.set(key, value)

      if (key.startsWith('mobile_') || Object.keys(theme.properties).includes('mobile_' + key)) {
        return
      }

      formData.set('mobile_' + key, null)
    })

    return this.$eventService.updateEvent(eventId, formData)
  }

  getMappedEventPropertyKey(eventPropertyKey, mobile = false) {
    return mobile ? `mobile_${eventPropertyKey}` : eventPropertyKey
  }
}
