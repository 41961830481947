import { SubscriptionOption } from '../models/subscription-option'
import { SUBSCRIPTION_OPTIONS } from '~/lib/constants/subscription_options'

export function mapSubscriptionOptions(subscriptionOptions) {
  return subscriptionOptions
    .filter((subscriptionOption) => subscriptionOption.id !== SUBSCRIPTION_OPTIONS.DEMO)
    .map((subscriptionOption) => mapSubscriptionOption(subscriptionOption))
}

export function mapSubscriptionOption(subscriptionOption) {
  return new SubscriptionOption({
    id: subscriptionOption.id,
    cost: subscriptionOption.cost,
    description: subscriptionOption.description,
    vat: subscriptionOption.vat,
    vatPercentage: subscriptionOption.vatPercentage,
    totalIncludingTax: subscriptionOption.totalIncludingTax,
  })
}
