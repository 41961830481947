/**
 * @memberOf momentshare.models.image
 * @constructor
 */
export class Image {
  constructor({
    id,
    eventId,
    thumbFilePath,
    filePath,
    originalFilePath,
    mimeType,
    likes,
    orderIndex,
    editableByCurrentUser,
  }) {
    this.id = id
    this.eventId = eventId
    this.thumbFilePath = thumbFilePath
    this.filePath = filePath
    this.originalFilePath = originalFilePath
    this.mimeType = mimeType
    this.likes = likes ?? 0
    this.orderIndex = orderIndex ?? null
    this.editableByCurrentUser = editableByCurrentUser ?? false
  }
}
