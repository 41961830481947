import { DEVICE_UID_COOKIE_KEY } from '~/lib/constants/cookies'
import { useDeviceStore } from '~/stores/device'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $clientCookieService } = nuxtApp
  const deviceStore = useDeviceStore()
  const currentDeviceUid = $clientCookieService.get(DEVICE_UID_COOKIE_KEY)

  if (currentDeviceUid) {
    deviceStore.setDeviceUid(currentDeviceUid)

    // Extend expiry date of current cookie
    $clientCookieService.set(DEVICE_UID_COOKIE_KEY, currentDeviceUid, {
      expires: 365,
    })

    return
  }

  $clientCookieService.set(DEVICE_UID_COOKIE_KEY, deviceStore.initDeviceUid(), {
    expires: 365,
  })
})
