/**
 * @memberOf momentshare.models.payment
 * @constructor
 */
export class PaymentMethod {
  constructor({ id, uid, description, image }) {
    this.id = id
    this.uid = uid
    this.description = description
    this.image = image
  }
}
