import { PaymentMethod } from '~/lib/models/payment-method'

export function mapPaymentMethods(paymentMethods) {
  return paymentMethods.map((paymentMethod) => mapPaymentMethod(paymentMethod))
}

export function mapPaymentMethod(paymentMethod) {
  return new PaymentMethod({
    id: paymentMethod.id,
    uid: paymentMethod.uid,
    description: paymentMethod.description,
    image: paymentMethod.image,
  })
}
