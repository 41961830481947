
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexLQl86L9kYq_IVMteWq5AL157T1oxtPGmHSeYNBKA7nAMeta } from "/usr/local/src/party-pictures-fe/pages/index.vue?macro=true";
import { default as indexlHmwOt7EeqlMGuZ9gWIT8Uht_7rAPaZG3eWWRXgrpQgMeta } from "/usr/local/src/party-pictures-fe/pages/about/index.vue?macro=true";
import { default as index5AJWZYTgCeW3j58sr9SfM9yMbjhrBnopfyJSijyJFnMMeta } from "/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue?macro=true";
import { default as indexc4CoWXDcFOj1m7jVqDbQe3rT6cap1vRatkXtPuDpE40Meta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue?macro=true";
import { default as indextzAZkCDi4pE1mldD6vFrw1XpOj4A8V4n0ZH49yfhiIwMeta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue?macro=true";
import { default as indexbyNF06FC6ZHRjCY_a_WZdbO5SjSm2hssSOx0qRWHSD4Meta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue?macro=true";
import { default as indexvIyxtLoAjOR0qMuPxEdhV7sA51MBxP7_45DU8_soWthjAMeta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue?macro=true";
import { default as index2YgDkXqKrrw_45Sm9a4mBI8tAGF8qU_izlU1q5FGWau_45kMeta } from "/usr/local/src/party-pictures-fe/pages/account/events/index.vue?macro=true";
import { default as events9k397XHC1PL1JsTdoZkSZJEckN8blPb_45jrGvac_wSa0Meta } from "/usr/local/src/party-pictures-fe/pages/account/events.vue?macro=true";
import { default as indexVx7FUfQmr8Msg97_457fzhmi6fJ2q0z_456lcFe2gvXFb20Meta } from "/usr/local/src/party-pictures-fe/pages/account/help/index.vue?macro=true";
import { default as index7FexHfrklF8J_eDBCNYzTpIeE1LfFEMS1iNakiUfh64Meta } from "/usr/local/src/party-pictures-fe/pages/account/index.vue?macro=true";
import { default as indexjgzanJoMRRZZt33FZoyaZOM1aLynL7UKqfICWPaHvEcMeta } from "/usr/local/src/party-pictures-fe/pages/account/orders/index.vue?macro=true";
import { default as indexp3rswIPexLX_FSkKm_45q27rL4wpV31Q7Ro7FqydKYBFIMeta } from "/usr/local/src/party-pictures-fe/pages/account/user/index.vue?macro=true";
import { default as indexKCq8Xlz4ZJrgWlU2k0ehqwyscg0nleXhBn_452FRPVP3EMeta } from "/usr/local/src/party-pictures-fe/pages/admin/index.vue?macro=true";
import { default as indexx_mImxqBE33qQR4tG4iQ5Kig8_UPLbXTbX2MOUJLUmwMeta } from "/usr/local/src/party-pictures-fe/pages/birthday/index.vue?macro=true";
import { default as indexJBmQlLg8kXq2E_51c6YkpkGm5GkebIgHb5cnphGtGeMMeta } from "/usr/local/src/party-pictures-fe/pages/christmas/index.vue?macro=true";
import { default as index71pwjy9admDEmyRFh8STE3yANl4PIXi2QsEclRTXkV0Meta } from "/usr/local/src/party-pictures-fe/pages/company-outing/index.vue?macro=true";
import { default as indexnz_459vfMDyidskd3g9_456gEQV0xJK2j4RplcBx1CwurO4Meta } from "/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue?macro=true";
import { default as _91event_934j0GgCuvGa9LIXMn8a08Dv4Up4L8gXVouKLigqLXR08Meta } from "/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue?macro=true";
import { default as indexsw_YKeZmPj0hq1PuEYUkhFeXOIckkZfDL7nlLBfnHR4Meta } from "/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue?macro=true";
import { default as indexQOaqR3gIHZFvIC_ov4qu88vdFjUHFO_hY4h_45lJSrEkwMeta } from "/usr/local/src/party-pictures-fe/pages/help-faq/index.vue?macro=true";
import { default as indexcQlSBq5F3pLyl7R8xNCLPZVB49r8_45Lrdt4LIMumbbFQMeta } from "/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue?macro=true";
import { default as indexrlb5n5YBjdFemhjjn3yyC4LI1y2aHjEACLs92haJatsMeta } from "/usr/local/src/party-pictures-fe/pages/login/index.vue?macro=true";
import { default as indexUBvD3Rr0wreey2rFLwzVSEvrtGW5VcIOSdnAf1u0XFkMeta } from "/usr/local/src/party-pictures-fe/pages/order/demo/index.vue?macro=true";
import { default as indexyNoTAdgNqtPn12G4_45_45h1OxRVorX9PCQCN4JbZ3yJiZgMeta } from "/usr/local/src/party-pictures-fe/pages/order/index.vue?macro=true";
import { default as indexsnaSbxYX14XVnBGa9nQDqu60I7JT88Enye1IV4GVudQMeta } from "/usr/local/src/party-pictures-fe/pages/prices/index.vue?macro=true";
import { default as indexeGN_45fpcjel8ShMOUmyycU9rZ8THkdpYNHWOdNsXl7kYMeta } from "/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue?macro=true";
import { default as indexfKxPN2206oKLialE_ZMsWI6aivD_uUBrts4tGwG_vo4Meta } from "/usr/local/src/party-pictures-fe/pages/register/index.vue?macro=true";
import { default as index2DwfYk85qq9p_OmhInhsaTpNHvm1l0OFqoYPGuivS_QMeta } from "/usr/local/src/party-pictures-fe/pages/reset-password/index.vue?macro=true";
import { default as indexg3LcD_3kbTjvf5LJ2vB_xXdSQP4jMUIAT7oh9rnNJeMMeta } from "/usr/local/src/party-pictures-fe/pages/reviews/index.vue?macro=true";
import { default as indexziGtZiAvcDFrRb4IQRTeJXBIElbqc94Dx5Dnr6omBjIMeta } from "/usr/local/src/party-pictures-fe/pages/sport-event/index.vue?macro=true";
import { default as indexkm14PgVPgansMkJrIiMuz80Tveqrc_45iPRmdzBFk2gQIMeta } from "/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexEvayO_45yieldia1tZ5ARLK8b5BYX87DmoxRFagi5Rp_sMeta } from "/usr/local/src/party-pictures-fe/pages/vacation/index.vue?macro=true";
import { default as indexYJAEGaZfyOOWKv9AyA0tNbTZbU3A5GzS_451TBZIXoAycMeta } from "/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue?macro=true";
import { default as indextRyFHQv6uMa5eBdDaca131urcfZEjzd_45dNmXWnY30soMeta } from "/usr/local/src/party-pictures-fe/pages/wedding/index.vue?macro=true";
import { default as component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwEMeta } from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwE } from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "about___nl",
    path: "/nl/about",
    meta: indexlHmwOt7EeqlMGuZ9gWIT8Uht_7rAPaZG3eWWRXgrpQgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "about___es",
    path: "/es/about",
    meta: indexlHmwOt7EeqlMGuZ9gWIT8Uht_7rAPaZG3eWWRXgrpQgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "about___fr",
    path: "/fr/about",
    meta: indexlHmwOt7EeqlMGuZ9gWIT8Uht_7rAPaZG3eWWRXgrpQgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "about___de",
    path: "/de/about",
    meta: indexlHmwOt7EeqlMGuZ9gWIT8Uht_7rAPaZG3eWWRXgrpQgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    meta: indexlHmwOt7EeqlMGuZ9gWIT8Uht_7rAPaZG3eWWRXgrpQgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue")
  },
  {
    name: "account-connectors___nl",
    path: "/nl/account/connectors",
    meta: index5AJWZYTgCeW3j58sr9SfM9yMbjhrBnopfyJSijyJFnMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: "account-connectors___es",
    path: "/es/account/connectors",
    meta: index5AJWZYTgCeW3j58sr9SfM9yMbjhrBnopfyJSijyJFnMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: "account-connectors___fr",
    path: "/fr/account/connectors",
    meta: index5AJWZYTgCeW3j58sr9SfM9yMbjhrBnopfyJSijyJFnMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: "account-connectors___de",
    path: "/de/account/connectors",
    meta: index5AJWZYTgCeW3j58sr9SfM9yMbjhrBnopfyJSijyJFnMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: "account-connectors___en",
    path: "/en/account/connectors",
    meta: index5AJWZYTgCeW3j58sr9SfM9yMbjhrBnopfyJSijyJFnMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue")
  },
  {
    name: events9k397XHC1PL1JsTdoZkSZJEckN8blPb_45jrGvac_wSa0Meta?.name,
    path: "/nl/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___nl",
    path: ":event()/design/:type()",
    meta: indexc4CoWXDcFOj1m7jVqDbQe3rT6cap1vRatkXtPuDpE40Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___nl",
    path: ":event()/design",
    meta: indextzAZkCDi4pE1mldD6vFrw1XpOj4A8V4n0ZH49yfhiIwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___nl",
    path: ":event()",
    meta: indexbyNF06FC6ZHRjCY_a_WZdbO5SjSm2hssSOx0qRWHSD4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___nl",
    path: ":event()/properties",
    meta: indexvIyxtLoAjOR0qMuPxEdhV7sA51MBxP7_45DU8_soWthjAMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___nl",
    path: "",
    meta: index2YgDkXqKrrw_45Sm9a4mBI8tAGF8qU_izlU1q5FGWau_45kMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  }
]
  },
  {
    name: events9k397XHC1PL1JsTdoZkSZJEckN8blPb_45jrGvac_wSa0Meta?.name,
    path: "/es/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___es",
    path: ":event()/design/:type()",
    meta: indexc4CoWXDcFOj1m7jVqDbQe3rT6cap1vRatkXtPuDpE40Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___es",
    path: ":event()/design",
    meta: indextzAZkCDi4pE1mldD6vFrw1XpOj4A8V4n0ZH49yfhiIwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___es",
    path: ":event()",
    meta: indexbyNF06FC6ZHRjCY_a_WZdbO5SjSm2hssSOx0qRWHSD4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___es",
    path: ":event()/properties",
    meta: indexvIyxtLoAjOR0qMuPxEdhV7sA51MBxP7_45DU8_soWthjAMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___es",
    path: "",
    meta: index2YgDkXqKrrw_45Sm9a4mBI8tAGF8qU_izlU1q5FGWau_45kMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  }
]
  },
  {
    name: events9k397XHC1PL1JsTdoZkSZJEckN8blPb_45jrGvac_wSa0Meta?.name,
    path: "/fr/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___fr",
    path: ":event()/design/:type()",
    meta: indexc4CoWXDcFOj1m7jVqDbQe3rT6cap1vRatkXtPuDpE40Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___fr",
    path: ":event()/design",
    meta: indextzAZkCDi4pE1mldD6vFrw1XpOj4A8V4n0ZH49yfhiIwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___fr",
    path: ":event()",
    meta: indexbyNF06FC6ZHRjCY_a_WZdbO5SjSm2hssSOx0qRWHSD4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___fr",
    path: ":event()/properties",
    meta: indexvIyxtLoAjOR0qMuPxEdhV7sA51MBxP7_45DU8_soWthjAMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___fr",
    path: "",
    meta: index2YgDkXqKrrw_45Sm9a4mBI8tAGF8qU_izlU1q5FGWau_45kMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  }
]
  },
  {
    name: events9k397XHC1PL1JsTdoZkSZJEckN8blPb_45jrGvac_wSa0Meta?.name,
    path: "/de/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___de",
    path: ":event()/design/:type()",
    meta: indexc4CoWXDcFOj1m7jVqDbQe3rT6cap1vRatkXtPuDpE40Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___de",
    path: ":event()/design",
    meta: indextzAZkCDi4pE1mldD6vFrw1XpOj4A8V4n0ZH49yfhiIwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___de",
    path: ":event()",
    meta: indexbyNF06FC6ZHRjCY_a_WZdbO5SjSm2hssSOx0qRWHSD4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___de",
    path: ":event()/properties",
    meta: indexvIyxtLoAjOR0qMuPxEdhV7sA51MBxP7_45DU8_soWthjAMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___de",
    path: "",
    meta: index2YgDkXqKrrw_45Sm9a4mBI8tAGF8qU_izlU1q5FGWau_45kMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  }
]
  },
  {
    name: events9k397XHC1PL1JsTdoZkSZJEckN8blPb_45jrGvac_wSa0Meta?.name,
    path: "/en/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue"),
    children: [
  {
    name: "account-events-event-design-type___en",
    path: ":event()/design/:type()",
    meta: indexc4CoWXDcFOj1m7jVqDbQe3rT6cap1vRatkXtPuDpE40Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/[type]/index.vue")
  },
  {
    name: "account-events-event-design___en",
    path: ":event()/design",
    meta: indextzAZkCDi4pE1mldD6vFrw1XpOj4A8V4n0ZH49yfhiIwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/design/index.vue")
  },
  {
    name: "account-events-event___en",
    path: ":event()",
    meta: indexbyNF06FC6ZHRjCY_a_WZdbO5SjSm2hssSOx0qRWHSD4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue")
  },
  {
    name: "account-events-event-properties___en",
    path: ":event()/properties",
    meta: indexvIyxtLoAjOR0qMuPxEdhV7sA51MBxP7_45DU8_soWthjAMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue")
  },
  {
    name: "account-events___en",
    path: "",
    meta: index2YgDkXqKrrw_45Sm9a4mBI8tAGF8qU_izlU1q5FGWau_45kMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue")
  }
]
  },
  {
    name: "account-help___nl",
    path: "/nl/account/help",
    meta: indexVx7FUfQmr8Msg97_457fzhmi6fJ2q0z_456lcFe2gvXFb20Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account-help___es",
    path: "/es/account/help",
    meta: indexVx7FUfQmr8Msg97_457fzhmi6fJ2q0z_456lcFe2gvXFb20Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account-help___fr",
    path: "/fr/account/help",
    meta: indexVx7FUfQmr8Msg97_457fzhmi6fJ2q0z_456lcFe2gvXFb20Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account-help___de",
    path: "/de/account/help",
    meta: indexVx7FUfQmr8Msg97_457fzhmi6fJ2q0z_456lcFe2gvXFb20Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account-help___en",
    path: "/en/account/help",
    meta: indexVx7FUfQmr8Msg97_457fzhmi6fJ2q0z_456lcFe2gvXFb20Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue")
  },
  {
    name: "account___nl",
    path: "/nl/account",
    meta: index7FexHfrklF8J_eDBCNYzTpIeE1LfFEMS1iNakiUfh64Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account___es",
    path: "/es/account",
    meta: index7FexHfrklF8J_eDBCNYzTpIeE1LfFEMS1iNakiUfh64Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account___fr",
    path: "/fr/account",
    meta: index7FexHfrklF8J_eDBCNYzTpIeE1LfFEMS1iNakiUfh64Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account___de",
    path: "/de/account",
    meta: index7FexHfrklF8J_eDBCNYzTpIeE1LfFEMS1iNakiUfh64Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: index7FexHfrklF8J_eDBCNYzTpIeE1LfFEMS1iNakiUfh64Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue")
  },
  {
    name: "account-orders___nl",
    path: "/nl/account/orders",
    meta: indexjgzanJoMRRZZt33FZoyaZOM1aLynL7UKqfICWPaHvEcMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___es",
    path: "/es/account/orders",
    meta: indexjgzanJoMRRZZt33FZoyaZOM1aLynL7UKqfICWPaHvEcMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___fr",
    path: "/fr/account/orders",
    meta: indexjgzanJoMRRZZt33FZoyaZOM1aLynL7UKqfICWPaHvEcMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___de",
    path: "/de/account/orders",
    meta: indexjgzanJoMRRZZt33FZoyaZOM1aLynL7UKqfICWPaHvEcMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___en",
    path: "/en/account/orders",
    meta: indexjgzanJoMRRZZt33FZoyaZOM1aLynL7UKqfICWPaHvEcMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue")
  },
  {
    name: "account-user___nl",
    path: "/nl/account/user",
    meta: indexp3rswIPexLX_FSkKm_45q27rL4wpV31Q7Ro7FqydKYBFIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "account-user___es",
    path: "/es/account/user",
    meta: indexp3rswIPexLX_FSkKm_45q27rL4wpV31Q7Ro7FqydKYBFIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "account-user___fr",
    path: "/fr/account/user",
    meta: indexp3rswIPexLX_FSkKm_45q27rL4wpV31Q7Ro7FqydKYBFIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "account-user___de",
    path: "/de/account/user",
    meta: indexp3rswIPexLX_FSkKm_45q27rL4wpV31Q7Ro7FqydKYBFIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "account-user___en",
    path: "/en/account/user",
    meta: indexp3rswIPexLX_FSkKm_45q27rL4wpV31Q7Ro7FqydKYBFIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue")
  },
  {
    name: "admin___nl",
    path: "/nl/admin",
    meta: indexKCq8Xlz4ZJrgWlU2k0ehqwyscg0nleXhBn_452FRPVP3EMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexKCq8Xlz4ZJrgWlU2k0ehqwyscg0nleXhBn_452FRPVP3EMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "admin___fr",
    path: "/fr/admin",
    meta: indexKCq8Xlz4ZJrgWlU2k0ehqwyscg0nleXhBn_452FRPVP3EMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexKCq8Xlz4ZJrgWlU2k0ehqwyscg0nleXhBn_452FRPVP3EMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexKCq8Xlz4ZJrgWlU2k0ehqwyscg0nleXhBn_452FRPVP3EMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue")
  },
  {
    name: "birthday___nl",
    path: "/nl/birthday",
    meta: indexx_mImxqBE33qQR4tG4iQ5Kig8_UPLbXTbX2MOUJLUmwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "birthday___es",
    path: "/es/birthday",
    meta: indexx_mImxqBE33qQR4tG4iQ5Kig8_UPLbXTbX2MOUJLUmwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "birthday___fr",
    path: "/fr/birthday",
    meta: indexx_mImxqBE33qQR4tG4iQ5Kig8_UPLbXTbX2MOUJLUmwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "birthday___de",
    path: "/de/birthday",
    meta: indexx_mImxqBE33qQR4tG4iQ5Kig8_UPLbXTbX2MOUJLUmwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "birthday___en",
    path: "/en/birthday",
    meta: indexx_mImxqBE33qQR4tG4iQ5Kig8_UPLbXTbX2MOUJLUmwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/birthday/index.vue")
  },
  {
    name: "christmas___nl",
    path: "/nl/christmas",
    meta: indexJBmQlLg8kXq2E_51c6YkpkGm5GkebIgHb5cnphGtGeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "christmas___es",
    path: "/es/christmas",
    meta: indexJBmQlLg8kXq2E_51c6YkpkGm5GkebIgHb5cnphGtGeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "christmas___fr",
    path: "/fr/christmas",
    meta: indexJBmQlLg8kXq2E_51c6YkpkGm5GkebIgHb5cnphGtGeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "christmas___de",
    path: "/de/christmas",
    meta: indexJBmQlLg8kXq2E_51c6YkpkGm5GkebIgHb5cnphGtGeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "christmas___en",
    path: "/en/christmas",
    meta: indexJBmQlLg8kXq2E_51c6YkpkGm5GkebIgHb5cnphGtGeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/christmas/index.vue")
  },
  {
    name: "company-outing___nl",
    path: "/nl/company-outing",
    meta: index71pwjy9admDEmyRFh8STE3yANl4PIXi2QsEclRTXkV0Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "company-outing___es",
    path: "/es/company-outing",
    meta: index71pwjy9admDEmyRFh8STE3yANl4PIXi2QsEclRTXkV0Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "company-outing___fr",
    path: "/fr/company-outing",
    meta: index71pwjy9admDEmyRFh8STE3yANl4PIXi2QsEclRTXkV0Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "company-outing___de",
    path: "/de/company-outing",
    meta: index71pwjy9admDEmyRFh8STE3yANl4PIXi2QsEclRTXkV0Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "company-outing___en",
    path: "/en/company-outing",
    meta: index71pwjy9admDEmyRFh8STE3yANl4PIXi2QsEclRTXkV0Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/company-outing/index.vue")
  },
  {
    name: "end-of-year___nl",
    path: "/nl/end-of-year",
    meta: indexnz_459vfMDyidskd3g9_456gEQV0xJK2j4RplcBx1CwurO4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "end-of-year___es",
    path: "/es/end-of-year",
    meta: indexnz_459vfMDyidskd3g9_456gEQV0xJK2j4RplcBx1CwurO4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "end-of-year___fr",
    path: "/fr/end-of-year",
    meta: indexnz_459vfMDyidskd3g9_456gEQV0xJK2j4RplcBx1CwurO4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "end-of-year___de",
    path: "/de/end-of-year",
    meta: indexnz_459vfMDyidskd3g9_456gEQV0xJK2j4RplcBx1CwurO4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "end-of-year___en",
    path: "/en/end-of-year",
    meta: indexnz_459vfMDyidskd3g9_456gEQV0xJK2j4RplcBx1CwurO4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/end-of-year/index.vue")
  },
  {
    name: "event-name-event___nl",
    path: "/nl/event/:name()/:event()",
    meta: _91event_934j0GgCuvGa9LIXMn8a08Dv4Up4L8gXVouKLigqLXR08Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "event-name-event___es",
    path: "/es/event/:name()/:event()",
    meta: _91event_934j0GgCuvGa9LIXMn8a08Dv4Up4L8gXVouKLigqLXR08Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "event-name-event___fr",
    path: "/fr/event/:name()/:event()",
    meta: _91event_934j0GgCuvGa9LIXMn8a08Dv4Up4L8gXVouKLigqLXR08Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "event-name-event___de",
    path: "/de/event/:name()/:event()",
    meta: _91event_934j0GgCuvGa9LIXMn8a08Dv4Up4L8gXVouKLigqLXR08Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "event-name-event___en",
    path: "/en/event/:name()/:event()",
    meta: _91event_934j0GgCuvGa9LIXMn8a08Dv4Up4L8gXVouKLigqLXR08Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue")
  },
  {
    name: "family-gathering___nl",
    path: "/nl/family-gathering",
    meta: indexsw_YKeZmPj0hq1PuEYUkhFeXOIckkZfDL7nlLBfnHR4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "family-gathering___es",
    path: "/es/family-gathering",
    meta: indexsw_YKeZmPj0hq1PuEYUkhFeXOIckkZfDL7nlLBfnHR4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "family-gathering___fr",
    path: "/fr/family-gathering",
    meta: indexsw_YKeZmPj0hq1PuEYUkhFeXOIckkZfDL7nlLBfnHR4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "family-gathering___de",
    path: "/de/family-gathering",
    meta: indexsw_YKeZmPj0hq1PuEYUkhFeXOIckkZfDL7nlLBfnHR4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "family-gathering___en",
    path: "/en/family-gathering",
    meta: indexsw_YKeZmPj0hq1PuEYUkhFeXOIckkZfDL7nlLBfnHR4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/family-gathering/index.vue")
  },
  {
    name: "help-faq___nl",
    path: "/nl/help-faq",
    meta: indexQOaqR3gIHZFvIC_ov4qu88vdFjUHFO_hY4h_45lJSrEkwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "help-faq___es",
    path: "/es/help-faq",
    meta: indexQOaqR3gIHZFvIC_ov4qu88vdFjUHFO_hY4h_45lJSrEkwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "help-faq___fr",
    path: "/fr/help-faq",
    meta: indexQOaqR3gIHZFvIC_ov4qu88vdFjUHFO_hY4h_45lJSrEkwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "help-faq___de",
    path: "/de/help-faq",
    meta: indexQOaqR3gIHZFvIC_ov4qu88vdFjUHFO_hY4h_45lJSrEkwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "help-faq___en",
    path: "/en/help-faq",
    meta: indexQOaqR3gIHZFvIC_ov4qu88vdFjUHFO_hY4h_45lJSrEkwMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue")
  },
  {
    name: "how-it-works___nl",
    path: "/nl/how-it-works",
    meta: indexcQlSBq5F3pLyl7R8xNCLPZVB49r8_45Lrdt4LIMumbbFQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "how-it-works___es",
    path: "/es/how-it-works",
    meta: indexcQlSBq5F3pLyl7R8xNCLPZVB49r8_45Lrdt4LIMumbbFQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "how-it-works___fr",
    path: "/fr/how-it-works",
    meta: indexcQlSBq5F3pLyl7R8xNCLPZVB49r8_45Lrdt4LIMumbbFQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "how-it-works___de",
    path: "/de/how-it-works",
    meta: indexcQlSBq5F3pLyl7R8xNCLPZVB49r8_45Lrdt4LIMumbbFQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "how-it-works___en",
    path: "/en/how-it-works",
    meta: indexcQlSBq5F3pLyl7R8xNCLPZVB49r8_45Lrdt4LIMumbbFQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue")
  },
  {
    name: "login___nl",
    path: "/nl/login",
    meta: indexrlb5n5YBjdFemhjjn3yyC4LI1y2aHjEACLs92haJatsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    meta: indexrlb5n5YBjdFemhjjn3yyC4LI1y2aHjEACLs92haJatsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: indexrlb5n5YBjdFemhjjn3yyC4LI1y2aHjEACLs92haJatsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "login___de",
    path: "/de/login",
    meta: indexrlb5n5YBjdFemhjjn3yyC4LI1y2aHjEACLs92haJatsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: indexrlb5n5YBjdFemhjjn3yyC4LI1y2aHjEACLs92haJatsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue")
  },
  {
    name: "order-demo___nl",
    path: "/nl/order/demo",
    meta: indexUBvD3Rr0wreey2rFLwzVSEvrtGW5VcIOSdnAf1u0XFkMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/demo/index.vue")
  },
  {
    name: "order-demo___es",
    path: "/es/order/demo",
    meta: indexUBvD3Rr0wreey2rFLwzVSEvrtGW5VcIOSdnAf1u0XFkMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/demo/index.vue")
  },
  {
    name: "order-demo___fr",
    path: "/fr/order/demo",
    meta: indexUBvD3Rr0wreey2rFLwzVSEvrtGW5VcIOSdnAf1u0XFkMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/demo/index.vue")
  },
  {
    name: "order-demo___de",
    path: "/de/order/demo",
    meta: indexUBvD3Rr0wreey2rFLwzVSEvrtGW5VcIOSdnAf1u0XFkMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/demo/index.vue")
  },
  {
    name: "order-demo___en",
    path: "/en/order/demo",
    meta: indexUBvD3Rr0wreey2rFLwzVSEvrtGW5VcIOSdnAf1u0XFkMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/demo/index.vue")
  },
  {
    name: "order___nl",
    path: "/nl/order",
    meta: indexyNoTAdgNqtPn12G4_45_45h1OxRVorX9PCQCN4JbZ3yJiZgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/index.vue")
  },
  {
    name: "order___es",
    path: "/es/order",
    meta: indexyNoTAdgNqtPn12G4_45_45h1OxRVorX9PCQCN4JbZ3yJiZgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/index.vue")
  },
  {
    name: "order___fr",
    path: "/fr/order",
    meta: indexyNoTAdgNqtPn12G4_45_45h1OxRVorX9PCQCN4JbZ3yJiZgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/index.vue")
  },
  {
    name: "order___de",
    path: "/de/order",
    meta: indexyNoTAdgNqtPn12G4_45_45h1OxRVorX9PCQCN4JbZ3yJiZgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/index.vue")
  },
  {
    name: "order___en",
    path: "/en/order",
    meta: indexyNoTAdgNqtPn12G4_45_45h1OxRVorX9PCQCN4JbZ3yJiZgMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/order/index.vue")
  },
  {
    name: "prices___nl",
    path: "/nl/prices",
    meta: indexsnaSbxYX14XVnBGa9nQDqu60I7JT88Enye1IV4GVudQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "prices___es",
    path: "/es/prices",
    meta: indexsnaSbxYX14XVnBGa9nQDqu60I7JT88Enye1IV4GVudQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "prices___fr",
    path: "/fr/prices",
    meta: indexsnaSbxYX14XVnBGa9nQDqu60I7JT88Enye1IV4GVudQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "prices___de",
    path: "/de/prices",
    meta: indexsnaSbxYX14XVnBGa9nQDqu60I7JT88Enye1IV4GVudQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "prices___en",
    path: "/en/prices",
    meta: indexsnaSbxYX14XVnBGa9nQDqu60I7JT88Enye1IV4GVudQMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue")
  },
  {
    name: "privacy-policy___nl",
    path: "/nl/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue")
  },
  {
    name: "register___nl",
    path: "/nl/register",
    meta: indexfKxPN2206oKLialE_ZMsWI6aivD_uUBrts4tGwG_vo4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "register___es",
    path: "/es/register",
    meta: indexfKxPN2206oKLialE_ZMsWI6aivD_uUBrts4tGwG_vo4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "register___fr",
    path: "/fr/register",
    meta: indexfKxPN2206oKLialE_ZMsWI6aivD_uUBrts4tGwG_vo4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "register___de",
    path: "/de/register",
    meta: indexfKxPN2206oKLialE_ZMsWI6aivD_uUBrts4tGwG_vo4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: indexfKxPN2206oKLialE_ZMsWI6aivD_uUBrts4tGwG_vo4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue")
  },
  {
    name: "reset-password___nl",
    path: "/nl/reset-password",
    meta: index2DwfYk85qq9p_OmhInhsaTpNHvm1l0OFqoYPGuivS_QMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___es",
    path: "/es/reset-password",
    meta: index2DwfYk85qq9p_OmhInhsaTpNHvm1l0OFqoYPGuivS_QMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___fr",
    path: "/fr/reset-password",
    meta: index2DwfYk85qq9p_OmhInhsaTpNHvm1l0OFqoYPGuivS_QMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___de",
    path: "/de/reset-password",
    meta: index2DwfYk85qq9p_OmhInhsaTpNHvm1l0OFqoYPGuivS_QMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: index2DwfYk85qq9p_OmhInhsaTpNHvm1l0OFqoYPGuivS_QMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue")
  },
  {
    name: "reviews___nl",
    path: "/nl/reviews",
    meta: indexg3LcD_3kbTjvf5LJ2vB_xXdSQP4jMUIAT7oh9rnNJeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reviews/index.vue")
  },
  {
    name: "reviews___es",
    path: "/es/reviews",
    meta: indexg3LcD_3kbTjvf5LJ2vB_xXdSQP4jMUIAT7oh9rnNJeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reviews/index.vue")
  },
  {
    name: "reviews___fr",
    path: "/fr/reviews",
    meta: indexg3LcD_3kbTjvf5LJ2vB_xXdSQP4jMUIAT7oh9rnNJeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reviews/index.vue")
  },
  {
    name: "reviews___de",
    path: "/de/reviews",
    meta: indexg3LcD_3kbTjvf5LJ2vB_xXdSQP4jMUIAT7oh9rnNJeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reviews/index.vue")
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    meta: indexg3LcD_3kbTjvf5LJ2vB_xXdSQP4jMUIAT7oh9rnNJeMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reviews/index.vue")
  },
  {
    name: "sport-event___nl",
    path: "/nl/sport-event",
    meta: indexziGtZiAvcDFrRb4IQRTeJXBIElbqc94Dx5Dnr6omBjIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "sport-event___es",
    path: "/es/sport-event",
    meta: indexziGtZiAvcDFrRb4IQRTeJXBIElbqc94Dx5Dnr6omBjIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "sport-event___fr",
    path: "/fr/sport-event",
    meta: indexziGtZiAvcDFrRb4IQRTeJXBIElbqc94Dx5Dnr6omBjIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "sport-event___de",
    path: "/de/sport-event",
    meta: indexziGtZiAvcDFrRb4IQRTeJXBIElbqc94Dx5Dnr6omBjIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "sport-event___en",
    path: "/en/sport-event",
    meta: indexziGtZiAvcDFrRb4IQRTeJXBIElbqc94Dx5Dnr6omBjIMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/sport-event/index.vue")
  },
  {
    name: "terms-and-conditions___nl",
    path: "/nl/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___es",
    path: "/es/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___fr",
    path: "/fr/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___de",
    path: "/de/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue")
  },
  {
    name: "vacation___nl",
    path: "/nl/vacation",
    meta: indexEvayO_45yieldia1tZ5ARLK8b5BYX87DmoxRFagi5Rp_sMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "vacation___es",
    path: "/es/vacation",
    meta: indexEvayO_45yieldia1tZ5ARLK8b5BYX87DmoxRFagi5Rp_sMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "vacation___fr",
    path: "/fr/vacation",
    meta: indexEvayO_45yieldia1tZ5ARLK8b5BYX87DmoxRFagi5Rp_sMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "vacation___de",
    path: "/de/vacation",
    meta: indexEvayO_45yieldia1tZ5ARLK8b5BYX87DmoxRFagi5Rp_sMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "vacation___en",
    path: "/en/vacation",
    meta: indexEvayO_45yieldia1tZ5ARLK8b5BYX87DmoxRFagi5Rp_sMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/vacation/index.vue")
  },
  {
    name: "wedding-anniversary___nl",
    path: "/nl/wedding-anniversary",
    meta: indexYJAEGaZfyOOWKv9AyA0tNbTZbU3A5GzS_451TBZIXoAycMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding-anniversary___es",
    path: "/es/wedding-anniversary",
    meta: indexYJAEGaZfyOOWKv9AyA0tNbTZbU3A5GzS_451TBZIXoAycMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding-anniversary___fr",
    path: "/fr/wedding-anniversary",
    meta: indexYJAEGaZfyOOWKv9AyA0tNbTZbU3A5GzS_451TBZIXoAycMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding-anniversary___de",
    path: "/de/wedding-anniversary",
    meta: indexYJAEGaZfyOOWKv9AyA0tNbTZbU3A5GzS_451TBZIXoAycMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding-anniversary___en",
    path: "/en/wedding-anniversary",
    meta: indexYJAEGaZfyOOWKv9AyA0tNbTZbU3A5GzS_451TBZIXoAycMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding-anniversary/index.vue")
  },
  {
    name: "wedding___nl",
    path: "/nl/wedding",
    meta: indextRyFHQv6uMa5eBdDaca131urcfZEjzd_45dNmXWnY30soMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: "wedding___es",
    path: "/es/wedding",
    meta: indextRyFHQv6uMa5eBdDaca131urcfZEjzd_45dNmXWnY30soMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: "wedding___fr",
    path: "/fr/wedding",
    meta: indextRyFHQv6uMa5eBdDaca131urcfZEjzd_45dNmXWnY30soMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: "wedding___de",
    path: "/de/wedding",
    meta: indextRyFHQv6uMa5eBdDaca131urcfZEjzd_45dNmXWnY30soMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: "wedding___en",
    path: "/en/wedding",
    meta: indextRyFHQv6uMa5eBdDaca131urcfZEjzd_45dNmXWnY30soMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/wedding/index.vue")
  },
  {
    name: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwEMeta?.name,
    path: "/nl/account/events/order",
    component: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwE
  },
  {
    name: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwEMeta?.name,
    path: "/es/account/events/order",
    component: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwE
  },
  {
    name: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwEMeta?.name,
    path: "/fr/account/events/order",
    component: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwE
  },
  {
    name: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwEMeta?.name,
    path: "/de/account/events/order",
    component: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwE
  },
  {
    name: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwEMeta?.name,
    path: "/en/account/events/order",
    component: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwE
  },
  {
    name: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwEMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubcdE6_45LPDCQOV9If77ftpgLvDVV7eSygmnBN3Eg0WiwE
  }
]