import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'
import { EventUploadButtonState } from '~/lib/models/event-upload-button-state'

/**
 * @memberOf momentshare.models.event
 * @constructor
 */
export class Event {
  /**
   * @param {string} id
   * @param {string} name
   * @param {string} status
   * @param {Date} date
   * @param {string} shortLink
   * @param {Date} [publicationDate]
   * @param {Date} [publicationExpiresAt]
   * @param {string} safeName
   * @param {momentshare.models.event.EventProperty[]} [properties]
   */
  constructor({ id, name, status, date, publicationDate, publicationExpiresAt, safeName, properties, shortLink }) {
    this.id = id
    this.name = name
    this.status = status
    this.safeName = safeName
    this.date = date
    this.shortLink = shortLink
    if (publicationDate) this.publicationDate = publicationDate
    if (publicationExpiresAt) this.publicationExpiresAt = publicationExpiresAt
    if (properties) this.properties = properties
  }
  // TODO: Remove in future
  get isLegacyEvent() {
    return !!this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD })
  }

  get isPublished() {
    return this.status === EVENT_STATES.PUBLISHED
  }

  get isEnded() {
    return this.status === EVENT_STATES.ENDED
  }

  get hasMomentshareUpgrade() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.HOSTING_ENABLED })
  }

  get isUsingGoogleDrive() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.USE_GOOGLE_DRIVE })
  }

  get isUsingDropbox() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.USE_DROPBOX })
  }

  get hasLargeVideoUpgrade() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.LARGE_VIDEOS_ALLOWED })
  }

  get galleryIsEnabled() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.ENABLE_GALLERY })
  }

  get uploadingForGuestsEnabled() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.UPLOADING_FOR_GUESTS_ENABLED }) ?? true
  }

  get hasAllUpgrades() {
    return this.hasMomentshareUpgrade && this.hasLargeVideoUpgrade
  }

  get isDemo() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.IS_DEMO })
  }

  get demoLimitIsHit() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.DEMO_LIMIT_IS_HIT })
  }

  // Design properties
  get backgroundImage() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BACKGROUND_IMAGE })
  }

  get originalBackgroundImage() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.ORIGINAL_BACKGROUND_IMAGE })
  }

  get mobileBackgroundImage() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BACKGROUND_IMAGE, mobile: true })
  }

  get originalMobileBackgroundImage() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.ORIGINAL_BACKGROUND_IMAGE, mobile: true })
  }

  get backgroundColor() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BACKGROUND_COLOR })
  }

  get mobileBackgroundColor() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BACKGROUND_COLOR, mobile: true })
  }

  get uploadText() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.UPLOAD_TEXT })
  }

  get mobileUploadText() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.UPLOAD_TEXT, mobile: true })
  }

  get textAlignment() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT })
  }

  get mobileTextAlignment() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.UPLOAD_TEXT_ALIGNMENT, mobile: true })
  }

  get font() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.FONT })
  }

  get mobileFont() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.FONT, mobile: true })
  }

  get textColor() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.TEXT_COLOR })
  }

  get mobileTextColor() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.TEXT_COLOR, mobile: true })
  }

  get buttonBackgroundColor() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR })
  }

  get mobileButtonBackgroundColor() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_BACKGROUND_COLOR, mobile: true })
  }

  get buttonTopPosition() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION, castBooleanToNumber: true })
  }

  get mobileButtonTopPosition() {
    return this.getPropertyValueByKey({
      key: EVENT_PROPERTY_KEY.BUTTON_TOP_POSITION,
      castBooleanToNumber: true,
      mobile: true,
    })
  }

  get buttonLeftPosition() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION, castBooleanToNumber: true })
  }

  get mobileButtonLeftPosition() {
    return this.getPropertyValueByKey({
      key: EVENT_PROPERTY_KEY.BUTTON_LEFT_POSITION,
      castBooleanToNumber: true,
      mobile: true,
    })
  }

  get buttonFontSize() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE, castBooleanToNumber: true })
  }

  get mobileButtonFontSize() {
    return this.getPropertyValueByKey({
      key: EVENT_PROPERTY_KEY.BUTTON_FONT_SIZE,
      castBooleanToNumber: true,
      mobile: true,
    })
  }

  get buttonBorderRadius() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS, castBooleanToNumber: true })
  }

  get mobileButtonBorderRadius() {
    return this.getPropertyValueByKey({
      key: EVENT_PROPERTY_KEY.BUTTON_BORDER_RADIUS,
      castBooleanToNumber: true,
      mobile: true,
    })
  }

  get buttonBorderEnabled() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED })
  }

  get mobileButtonBorderEnabled() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_BORDER_ENABLED, mobile: true })
  }

  get buttonBorderWidth() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH, castBooleanToNumber: true })
  }

  get mobileButtonBorderWidth() {
    return this.getPropertyValueByKey({
      key: EVENT_PROPERTY_KEY.BUTTON_BORDER_WIDTH,
      castBooleanToNumber: true,
      mobile: true,
    })
  }

  get buttonBorderColor() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR })
  }

  get mobileButtonBorderColor() {
    return this.getPropertyValueByKey({ key: EVENT_PROPERTY_KEY.BUTTON_BORDER_COLOR, mobile: true })
  }

  get uploadButtonState() {
    return new EventUploadButtonState({
      text: this.uploadText,
      font: this.font,
      backgroundColor: this.buttonBackgroundColor,
      color: this.textColor,
      top: this.buttonTopPosition,
      left: this.buttonLeftPosition,
      fontSize: this.buttonFontSize,
      borderRadius: this.buttonBorderRadius,
      border: this.buttonBorderEnabled,
      borderWidth: this.buttonBorderWidth,
      borderColor: this.buttonBorderColor,
      textAlignment: this.textAlignment,
    })
  }

  get mobileUploadButtonState() {
    return new EventUploadButtonState({
      text: this.mobileUploadText,
      font: this.mobileFont,
      backgroundColor: this.mobileButtonBackgroundColor,
      color: this.mobileTextColor,
      top: this.mobileButtonTopPosition,
      left: this.mobileButtonLeftPosition,
      fontSize: this.mobileButtonFontSize,
      borderRadius: this.mobileButtonBorderRadius,
      border: this.mobileButtonBorderEnabled,
      borderWidth: this.mobileButtonBorderWidth,
      borderColor: this.mobileButtonBorderColor,
      textAlignment: this.mobileTextAlignment,
    })
  }

  getPropertyValueByKey({ key, castBooleanToNumber = false, mobile = false }) {
    const valueKey = mobile ? 'mobileValue' : 'value'
    const value = this.properties.find((property) => property.key === key)?.[valueKey] ?? null

    if (!castBooleanToNumber) {
      return value
    }

    if (typeof value !== 'boolean') {
      return value
    }

    return value ? 1 : 0
  }
}

export const EVENT_STATES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ENDED: 'ended',
  DISABLED: 'disabled',
}
