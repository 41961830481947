/**
 * @memberOf momentshare.models.subscription
 * @constructor
 */
export class SubscriptionOption {
  constructor({ id, cost, description, vat, vatPercentage, totalIncludingTax }) {
    this.id = id
    this.cost = cost
    this.description = description
    this.vat = vat
    this.vatPercentage = vatPercentage
    this.totalIncludingTax = totalIncludingTax
  }
}
