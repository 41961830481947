import axios from 'axios'
import { USER_AUTH_COOKIE_KEY } from '../constants/cookies'
import { mapUser } from '~/lib/mappers/user-mapper'
import { useUserStore } from '~/stores/user'
import { ROUTES } from '~/lib/constants/routes'
import { API_AUTH_ERROR_TOKENS } from '~/lib/constants/api-auth-error-tokens'
import { useDeviceStore } from '~/stores/device'

/**
 * @typedef {ApiService}
 * @alias this.$apiService
 */
export class ApiService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
    this.config = nuxtApp.$config.public
    this.route = useRoute()
    this.userStore = useUserStore()
    this.localePath = useLocalePath()
    this.deviceStore = useDeviceStore()

    this.instance = axios.create({
      baseURL: this.config.apiUrl,
      headers: {
        apiKey: this.config.apiKey,
      },
    })

    this.authInstance = axios.create({
      baseURL: this.config.apiUrl,
      headers: {
        apiKey: this.config.apiKey,
      },
    })

    this.setTokenInterceptor(this.instance)
    this.setDeviceInterceptor(this.instance)
    this.setErrorInterceptor(this.instance)

    this.setDeviceInterceptor(this.authInstance)
    this.setErrorInterceptor(this.authInstance)
  }

  init() {
    this.$clientCookieService = this.nuxtApp.$clientCookieService
    this.$sentryService = this.nuxtApp.$sentryService
    this.$userService = this.nuxtApp.$userService
  }

  /**
   * @param {Object} tokenDetails
   * @returns {void}
   */
  setToken(tokenDetails) {
    this.setTokenToStore(tokenDetails)
    this.setTokenInCookies(tokenDetails)
  }

  setTokenInCookies(tokenDetails) {
    this.$clientCookieService.set(USER_AUTH_COOKIE_KEY, JSON.stringify(tokenDetails), { expires: 180 })
  }

  setTokenToStore(tokenDetails) {
    this.userStore.setToken(tokenDetails)
  }

  /**
   * @returns {Promise<String>}
   */
  async getToken() {
    let tokenDetails = this.userStore.token

    if (!tokenDetails) {
      //check from cookies
      tokenDetails = await this.$clientCookieService.get(USER_AUTH_COOKIE_KEY)
      tokenDetails = tokenDetails ? JSON.parse(tokenDetails) : null
    }

    if (!tokenDetails) {
      return Promise.resolve(null)
    }

    if (tokenDetails.expirationDate > new Date().getTime()) {
      this.setTokenToStore(tokenDetails)
      return tokenDetails.token
    }

    // Token is expired. Try to refresh. Return null if unsuccessful to force the 401 from api.
    return this.refreshToken(tokenDetails)
  }

  /**
   * @param {Object} tokenDetails
   * @returns {Promise<string>}
   */
  async refreshToken(tokenDetails) {
    return this.authInstance
      .post('/auth/refresh-token', {
        refreshToken: tokenDetails.refreshToken,
      })
      .then((response) => {
        this.setToken(response.data)

        return response.data.token
      })
      .catch(() => {
        return null
      })
  }

  /**
   * @param {string} username
   * @param {string} password
   * @returns {Promise<void>}
   */
  login(username, password) {
    return this.authInstance.post('/auth/login', { userName: username, password }).then((response) => {
      this.setToken(response.data)
    })
  }

  /**
   * @param {string} username
   * @param {string} password
   * @param {string} displayName
   * @param {string} localeCode
   * @returns {Promise<void>}
   */
  register({ username, password, displayName, localeCode }) {
    return this.instance
      .post('/auth/register', { userName: username, password, displayName, localeCode })
      .then((response) => {
        this.setToken(response.data)
      })
  }

  checkEmail({ email }) {
    return this.instance
      .get(`/auth/check-email?email=${encodeURIComponent(email)}`)
      .then((response) => response.status === 200)
  }

  /**
   * @returns {Promise<momentshare.models.user.User>}
   */
  getUser() {
    return this.instance.get('/user').then((response) => mapUser(response.data))
  }

  /**
   * @param {Object} axiosInstance
   * @returns {void}
   */
  setTokenInterceptor(axiosInstance) {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // We also can get an error here from the response interceptor
        if (!config) {
          return Promise.reject()
        }

        try {
          const token = await this.getToken()

          if (!token) {
            return config
          }

          config.headers = {
            ...config.headers,
            Authorization: token,
          }

          return config
        } catch (error) {
          return Promise.reject(error)
        }
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error)
      },
    )
  }

  /**
   * @param {Object} axiosInstance
   * @returns {void}
   */
  setErrorInterceptor(axiosInstance) {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const t = this.nuxtApp.$i18n.t

        if (error.status === 401 && API_AUTH_ERROR_TOKENS.includes(error.response.data)) {
          const router = this.nuxtApp.$router

          this.$userService.logout()

          router.push({ path: this.localePath(ROUTES.LOGIN), query: { error: error.response.data } })

          return Promise.reject(error)
        }

        if (error.response?.data?.message?.error?.errors?.length) {
          return Promise.reject(error.response?.data?.message?.error?.errors[0].message)
        }

        if (error.response?.data?.message) {
          const filteredMessage = error.response?.data?.message.replace(/[/.]/g, '') // Filter out forward slashes and dots. This handles Dropbox errors better.
          return Promise.reject(t(`api_errors.${filteredMessage}`))
        }

        return Promise.reject(error)
      },
    )
  }

  /**
   * @param {Object} axiosInstance
   * @returns {void}
   */
  setDeviceInterceptor(axiosInstance) {
    axiosInstance.interceptors.request.use((config) => {
      config.headers = {
        ...config.headers,
        'x-device-uid': this.deviceStore.deviceUid,
      }

      return config
    })
  }
}
