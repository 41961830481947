export const UPLOAD_STATES = {
  QUEUE: 'queue',
  UPLOADING: 'uploading',
  DONE: 'done',
  FAILED: 'failed',
  OVERSIZED: 'oversized',
}

export const MAX_UPLOAD_SIZE = 250000000
export const PREMIUM_MAX_UPLOAD_SIZE = 1024000000
export const VIDEO_MAX_UPLOAD_SIZE = 102400000000
