import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/usr/local/src/party-pictures-fe/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "/usr/local/src/party-pictures-fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/local/src/party-pictures-fe/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/usr/local/src/party-pictures-fe/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68 from "/usr/local/src/party-pictures-fe/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w from "/usr/local/src/party-pictures-fe/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o from "/usr/local/src/party-pictures-fe/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg from "/usr/local/src/party-pictures-fe/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_HVC5_pBnOqXuNIJWDoUz0EW8qR41YmLCqZ9a_t4Lvs8 from "/usr/local/src/party-pictures-fe/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ from "/usr/local/src/party-pictures-fe/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/usr/local/src/party-pictures-fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/usr/local/src/party-pictures-fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/usr/local/src/party-pictures-fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_initialize_services_jDsyhzXw17TbEsM3X4WqHv_YkW_9uSmplIg1mClQiNI from "/usr/local/src/party-pictures-fe/plugins/01.initialize-services.js";
import _02_initialize_device_uid_client_FIQ6hOas7Q6X2QiNRGfeoa1E1n42f2xsp9wa7amLErg from "/usr/local/src/party-pictures-fe/plugins/02.initialize-device-uid.client.js";
import _02_initialize_gtm_client_rbJSgZobHzCb0HQjBxo0KTtSwnQdY9vE_AZc8TPtEto from "/usr/local/src/party-pictures-fe/plugins/02.initialize-gtm.client.js";
import _02_initialize_user_client_yfqYTfa1RFccv12j3pUEkqcWrDzXGYkPxf_9L8vVBsU from "/usr/local/src/party-pictures-fe/plugins/02.initialize-user.client.js";
import _03_init_match_media_client_6AdA8w1wYAPk2ABgb9Z1A_zMuZnJe_JDSBzmyHlF_wM from "/usr/local/src/party-pictures-fe/plugins/03.init-match-media.client.js";
import _05_initialize_sentry_plugins_client_TWWy2G8Z3uHoUdU0ph3F_Jc7CW1Lo8FZ2DrypQX4dKI from "/usr/local/src/party-pictures-fe/plugins/05.initialize-sentry-plugins.client.js";
import error_handler_client_vLFqrgIW2JpSCQ94tc_JVIG1doNZ_EBse1WHfDppeHM from "/usr/local/src/party-pictures-fe/plugins/error-handler.client.js";
import _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM from "/usr/local/src/party-pictures-fe/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/usr/local/src/party-pictures-fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68,
  siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w,
  inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o,
  titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg,
  defaultsWaitI18n_HVC5_pBnOqXuNIJWDoUz0EW8qR41YmLCqZ9a_t4Lvs8,
  i18n_84QbCrEJidQfHX79evFy6rRgOFjwLXHugOxbWoIVeIQ,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  _01_initialize_services_jDsyhzXw17TbEsM3X4WqHv_YkW_9uSmplIg1mClQiNI,
  _02_initialize_device_uid_client_FIQ6hOas7Q6X2QiNRGfeoa1E1n42f2xsp9wa7amLErg,
  _02_initialize_gtm_client_rbJSgZobHzCb0HQjBxo0KTtSwnQdY9vE_AZc8TPtEto,
  _02_initialize_user_client_yfqYTfa1RFccv12j3pUEkqcWrDzXGYkPxf_9L8vVBsU,
  _03_init_match_media_client_6AdA8w1wYAPk2ABgb9Z1A_zMuZnJe_JDSBzmyHlF_wM,
  _05_initialize_sentry_plugins_client_TWWy2G8Z3uHoUdU0ph3F_Jc7CW1Lo8FZ2DrypQX4dKI,
  error_handler_client_vLFqrgIW2JpSCQ94tc_JVIG1doNZ_EBse1WHfDppeHM,
  _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]