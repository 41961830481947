import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'

export const useDeviceStore = defineStore('deviceStore', () => {
  const deviceUid = ref(null)

  function setDeviceUid(uid) {
    deviceUid.value = uid
  }

  function initDeviceUid() {
    deviceUid.value = uuidv4()

    return deviceUid.value
  }

  return {
    deviceUid,
    setDeviceUid,
    initDeviceUid,
  }
})
