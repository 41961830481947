import { defineStore } from 'pinia'

import actions from './actions'

export const useEventsStore = defineStore('eventsStore', {
  state: () => ({
    events: [],
    selectedEventId: null,
  }),
  actions,
  getters: {
    /**
     * @param {Object} state
     * @returns {boolean}
     */
    latestEvent(state) {
      return state.events[0] ?? null
    },

    /**
     * @param {Object} state
     * @returns {string|undefined}
     */
    currentSelectedEventId(state) {
      return state.selectedEventId ?? state.events[0]?.id
    },

    /**
     * @param {Object} state
     * @returns {function(string): momentshare.models.event.Event|undefined}
     */
    getEventById: (state) => (id) => {
      return state.events.find((event) => event.id === id)
    },

    /**
     * @param {Object} state
     * @returns {boolean}
     */
    hasOnlyMomentshareDriveEvents: (state) => state.events.every((event) => event.hasMomentshareUpgrade),

    /**
     * @param {Object} state
     * @returns {boolean}
     */
    hasOnlyDemoEvent: (state) => state.events.every((event) => event.isDemo),

    /**
     * @param {Object} state
     * @returns {boolean}
     */
    demoEvent: (state) => state.events.find((event) => event.isDemo),
  },
})
