export const appHead = {"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#ffc71e"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;700&display=swap"},{"rel":"preload","href":"https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap","as":"style","onload":"this.onload=null;this.rel='stylesheet'"},{"rel":"preload","href":"https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;700&display=swap","as":"style","onload":"this.onload=null;this.rel='stylesheet'"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"msapplication-TileColor","content":"#000000"},{"name":"theme-color","content":"#000"},{"property":"og:url","content":"https://momentshare.io"},{"property":"og:type","content":"website"},{"property":"og:image","content":"https://momentshare.io/android-chrome-256x256.png"},{"property":"og:image:width","content":"256px"},{"property":"og:image:height","content":"256px"},{"property":"og:site_name","content":"Momentshare"}],"style":[],"script":[],"noscript":[],"titleTemplate":"%s %separator Momentshare","title":"Momentshare","htmlAttrs":{"lang":"nl"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false